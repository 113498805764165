<script>
</script>

<svelte:head>
	<title>Future BCH</title>
	<meta name="description" content="Future Bitcoin Cash" />
	<link rel="icon" type="image/svg" href="/FBCH.svg" />
</svelte:head>

<section>
	<h1>Welcome to Future Bitcoin Cash!</h1>
	<p>FBCH are series of fungible tokens, with an incentive market for locking and unlocking Bitcoin Cash.</p>
	<p>This is a pre-release version to facilitate real world testing across a range of devices.</p>

</section>

<style>
	section {
		display: flex;
		flex-direction: column;
		flex: 0.6;
	}

	h1 {
		width: 100%;
	}

</style>
